
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationTeamCard>({
        components: {
        },
    })
    export default class OrganizationTeamCard extends Vue {
        public showed: boolean = false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        public handleShowMore() {
          this.showed = !this.showed;
        }
    }
