
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import OrganizationLinksCard from '@/components/organization/OrganizationLinksCard.vue';
    import OrganizationTeamCard from '@/components/OrganizationTeamCard.vue';
    import OrganizationThumbs from '@/components/organization/OrganizationThumbs.vue';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationInfos>({
        components: {
            OrganizationLinksCard,
            OrganizationTeamCard,
            OrganizationThumbs,
        },
    })
    export default class OrganizationInfos extends Vue {
        public showed: boolean = false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        public handleShowMore() {
          this.showed = !this.showed;
        }

        get isTooBig() {
            if (!this.organization.presentation) {
                return;
            }

            return this.organization.presentation.length > 270;
        }

      get presentation() {
        if (!this.isTooBig || this.showed) {
          return this.organization.presentation;
        }

        return `${this.organization.presentation?.slice(0, 270)}...`;
      }

        get noLinks() {
            return !this.organization.social_links.website &&
                !this.organization.social_links.instagram_link &&
                !this.organization.social_links.shop_link
            ;
        }

    }
